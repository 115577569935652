<template>
  <v-card
    class="px-11 py-4 mb-8"
  >
    <v-row>
      <v-col cols="6">
        <v-row class="card-title">
          {{ title }}
        </v-row>
        <v-row class="fw-14-500 gray-text">
          {{ tagline }}
        </v-row>
        <v-row v-if="exploreRoute">
          <v-btn
            outlined
            depressed
            color="primary"
            class="mt-6 explore-button"
            height="38"
            @click="goTo"
          >
            <span class="fw-11-700">Explore</span>
            <v-icon
              right
              small
            >
              {{ arrowIcon }}
            </v-icon>
          </v-btn>
        </v-row>
      </v-col>
      <v-col
        cols="6"
        class="d-flex justify-end align-self-end"
      >
        <img
          v-if="boxly"
          :src="require(`@/assets/img/home/${boxly}`)"
          width="250"
        >
      </v-col>
    </v-row>
    <v-row
      class="d-flex buttons-container mt-2"
      justify="space-between"
    >
      <v-col
        v-for="(button, index) in buttons"
        :key="index"
        class="px-0 d-flex flex-grow-0"
      >
        <v-btn
          v-if="button.icon"
          text
          color="primary"
          class="no-border-buttons fw-14-500"
          height="50"
          @click="goTo(button)"
        >
          <v-icon
            left
            size="25"
          >
            {{ button.icon }}
          </v-icon>
          <span class="ml-2">{{ button.text }}</span>
        </v-btn>
        <v-divider
          v-else
          vertical
          class="divider"
        />
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  name: 'Cards',
  props: {
    title: {
      type: String,
      default: ''
    },
    tagline: {
      type: String,
      default: ''
    },
    boxly: {
      type: String,
      default: ''
    },
    exploreRoute: {
      type: String,
      default: ''
    },
    buttons: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      arrowIcon: '$arrowIcon'
    };
  },
  methods: {
    goTo(button) {
      if (this.exploreRoute === 'InnovationShop') {
        if (button.index === 3) {
          // the service id is the same on STG and PROD
          this.$router.push({ name: 'ServiceDetailPage', params: { id: 'OzbXBoeR5E' } });
        } else if (button.index + 1) {
          this.$router.push({ name: this.exploreRoute, params: { index: button.index + 1 } });
        } else {
          this.$router.push({ name: this.exploreRoute });
        }
      } else {
        const route = button.subLink ? `/knowledge-hub?link=${button.subLink}` : '/knowledge-hub';
        this.$router.push({ path: route });
      }
    }
  }
};
</script>

<style scoped lang="scss">
$light-blue: #F2F8FF;
.v-card {
  border-radius: 10px;
}
.card-title {
  font-weight: 600;
  font-size: 23px;
  line-height: 28px;
  margin-bottom: 1rem;
}
.explore-button {
  border: 1px solid #B6D9FB;
  background-color: $light-blue;
  border-radius: 6px;
}
.buttons-container {
  .no-border-buttons {
    border-style: none;
    border-radius: 8px;
    text-transform: none;
    &:hover {
      background-color: $light-blue;
    }
  }
  .v-divider {
    height: 35px !important;
    min-height: initial;
    align-self: center;
  }
}
</style>

<template>
  <v-container
    px-10
    class="home-container"
  >
    <v-row>
      <v-col
        md="12"
        lg="10"
        xl="10"
      >
        <!--Welcome-->
        <v-row class="mb-4">
          <v-col>
            <h3>
              <span class="fw-25-600">
                {{ userName }}, Welcome!
              </span>
            </h3>
            <label class="gray-text">
              Let’s kick it and bring your KICKBOX Program to the next level
            </label>
          </v-col>
        </v-row>
        <!--KICKBOX Funnel-->
        <v-row class="fw-18-600">
          <v-col>
            <span>KICKBOX Funnel</span>
          </v-col>
          <v-col class="text-right">
            <router-link
              :to="{ name: 'StatisticDashboard'}"
              tag="a"
              class="fw-11-700 text-decoration-none"
            >
              VIEW DASHBOARD
            </router-link>
          </v-col>
        </v-row>
        <v-row>
          <v-col class="d-flex justify-space-between">
            <v-card
              v-for="phase in phases"
              :key="phase.name"
              class="phase-card"
              min-height="88"
              :style="{ color: phaseData(phase).color }"
            >
              <template v-if="isLoading.projects">
                <v-skeleton-loader
                  class="pt-3 ml-4 pr-12"
                  type="heading"
                />
                <v-skeleton-loader
                  class="pt-4 mx-4"
                  type="text"
                />
              </template>
              <template v-else>
                <v-card-title class="fw-30-600">
                  {{ amountProjectsPhase(phase) }}
                </v-card-title>
                <v-card-subtitle
                  class="fw-14-500"
                  :style="{ color: phaseData(phase).color }"
                >
                  {{ phaseData(phase).title }}
                </v-card-subtitle>
              </template>
            </v-card>
          </v-col>
        </v-row>
        <!--KICKBOX program-->
        <v-row>
          <v-col class="fw-18-600 mb-3">
            Boost your KICKBOX Program
          </v-col>
        </v-row>
        <cards
          title="Innovation Shop"
          tagline="The Innovation Shop helps you to grow the KICKBOX Program
          in your company and beyond."
          boxly="boxly_red_and_blue.svg"
          explore-route="InnovationShop"
          :buttons="innovationShopButtons"
        />
        <cards
          v-if="showKnowledgeHubData"
          title="Knowledge Hub"
          tagline="Browse through rready-to-use materials and
      bring your KICKBOX Program to the next level."
          boxly="boxly_gold.svg"
          explore-route="KnowledgeHub"
          :buttons="knowledgeHubButtons"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { DashboardUtils } from '@kickbox/common-admin';
import { PENDING, REDBOX_IDEA, REDBOX_FUNDING, BLUEBOX, GOLDBOX } from '@kickbox/common-util';
import { mapGetters } from 'vuex';
import { projectService, userService } from '@/services';
import Cards from '@/components/home/Cards';
import { phaseUtils } from '@/utils';

export default {
  name: 'Home',
  components: {
    Cards
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (!vm.dashboardProjects.length) {
        projectService.getDashboardProjects(true);
      }
    });
    next();
  },
  data() {
    return {
      innovationShopButtons: [
        { index: 0, icon: '$groupArrowsIcon', text: 'Workshops' },
        {}, // there is a better approach to add a divider using justify-between?
        { index: 1, icon: '$personSignIcon', text: 'Coaching' },
        {},
        { index: 2, icon: '$dumbbelIcon', text: 'Coach Training' },
        {},
        { index: 3, icon: '$mugHotIcon', text: 'Merchandising' },
      ],
      knowledgeHubButtons: [
        { icon: '$booksIcon', text: 'Marketing Materials', subLink: 'Marketing-f373e75915ea4527abadd04df88866fb' },
        {},
        { icon: '$usersIcon', text: 'Community Building', subLink: 'Community-Building-7fcd409273b741e78c79c9bdaed40953' },
        {},
        { icon: '$presentationScreenIcon', text: 'Pitch Events', subLink: 'Pitch-Event-cc2f199fe22d410d80688d4ea263367b' },
        {},
        { icon: '$arrowsDirectionsIcon', text: 'Scale Up', subLink: 'Scale-up-0b0c6bc77b8849b1b19d278b5cbcfe0c' },
      ],
      phases: [
        PENDING,
        REDBOX_IDEA,
        REDBOX_FUNDING,
        BLUEBOX,
        GOLDBOX
      ]
    };
  },
  computed: {
    ...mapGetters(['dashboardProjects', 'isLoading', 'company']),
    userName() {
      return userService.getCurrentUserName();
    },
    showKnowledgeHubData() {
      const { knowledgeHubData } = this.company;
      return knowledgeHubData.url && knowledgeHubData.enabled;
    }
  },
  methods: {
    phaseData(phase) {
      if (phase.name === PENDING.name) {
        return { ...phaseUtils.phases[phase.name], color: 'black' };
      }
      return phaseUtils.phases[phase.name];
    },
    amountProjectsPhase(phase) {
      return DashboardUtils.activeProjectsByPhase(phase.name).length;
    }
  }
};
</script>

<style scoped lang="scss">
.home-container {
  .row {
    max-width: 1200px;
  }
  .phase-card {
    width: 158px;
    border-radius: 10px;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    .v-card__title {
      margin-bottom: 0.3rem;
    }
  }
}
</style>
